import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { registerUser, changePage } from "../../../redux/actions/userActions";

import OtpButton from "./component/otp";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useTranslation } from 'react-i18next';
//import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

const mySwal = withReactContent(Swal);

const Register = ({ query, register, isLoading, error, change, operatorId, machine, outletId }) => {
	const [show, setShow] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [sendOtp, setSendOtp] = useState(false);
	const [number, setNumber] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [month, setMonth] = useState("");
	const [day, setDay] = useState("");
	const [year, setYear] = useState("");
	const [password, setPassword] = useState("");
	const [confirmationPassword, setConfirmationPassword] = useState("");
	const [otp, setOtp] = useState("");
	const [referId, setReferId] = useState("");
	const [agree, setAgree] = useState(false);
	const [loading, setLoading] = useState(true);
	const [referral, setReferral] = useState(false);

	useEffect(() => {
		axios
			.post("/api/nonuser/getReferralSetting", { operatorId: operatorId })
			.then(res => {
				setReferral(res.data.data);
				if (queryString.parse(query).referralCode) {
					setReferId(queryString.parse(query).referralCode);
				}
				setLoading(false);
			})
			.catch(err => {
				mySwal.fire(t("Error"), t(err.response.data.error), "error");
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { t } = useTranslation();

	const renderDates = () => {
		let dates = [];
		if (month === "January" || month === "March" || month === "May" || month === "July" || month === "August" || month === "October" || month === "December") {
			dates = [];
			for (let i = 1; i < 32; i++) {
				dates.push(i);
			}
			return dates.map(date => (
				<option key={date} value={date}>
					{date}
				</option>
			));
		} else if (month === "Febuary" || month === "April" || month === "June" || month === "September" || month === "November") {
			dates = [];
			for (let i = 1; i < 31; i++) {
				dates.push(i);
			}
			return dates.map(date => (
				<option key={date} value={date}>
					{date}
				</option>
			));
		} else {
			return null;
		}
	};

	const renderYears = () => {
		let pushYear = new Date().getFullYear() - 4;
		let years = [];
		for (let i = 80; i >= 0; i--) {
			years.push(pushYear);
			pushYear--;
		}
		return years.map(year => (
			<option value={year} key={year}>
				{year}
			</option>
		));
	};

	const handleSubmit = e => {
		e.preventDefault();

		const numberRegex = /^\d+$/;
		const { operatorCode } = queryString.parse(query);
		const operatorID = operatorCode || operatorId;

		// console.log({name, email , month , day , password , referId , storeId});
		// number , name , email , month , day , password , referId , storeId

		if (!operatorID) {
			mySwal.fire(t("Info"), t("Unable to register without a QRCode"), "info");
		} else {
			if (!agree) {
				mySwal.fire("", t("Please read and agree to the privacy policies, terms and conditions"), "info");
			} else {
				if (password.length < 6) {
					mySwal.fire(t("Info"), t("Password length must be at least 6 characters"), "info");
				} else if (!numberRegex.test(number) || number.length <= 9 || number.length >= 12 || number.substr(0, 2) !== "01") {
					mySwal.fire({
						title: t("Error"),
						icon: "error",
						html: t(`Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>`)
					});
				} else if (password !== confirmationPassword) {
					mySwal.fire(t("Error"), t("Password and confirmation password should be same."), "error");
				} else {

					// Initialize an agent at application startup.
					// const fpPromise = FingerprintJS.load({ apiKey: process.env.REACT_APP_FINGERPRINTJS_API_KEY })

					// fpPromise
					// 	.then(fp => fp.get())
					// 	.then(result => {
							const sendThis = {
								number,
								name,
								email,
								month,
								day,
								year,
								password,
								referId,
								operatorCode: operatorID,
								otp,
								//fingerprint: result.visitorId
							};

							if (machine) {
								sendThis.outletCode = machine.outletId
							}

							if (outletId) {
								sendThis.outletCode = outletId
							}

							if (!sendOtp && !otp) {
								setLoading(true);
								axios
									.post("/api/user/auth/register", sendThis)
									.then(res => {
										setLoading(false);
										setSendOtp(true);
									})
									.catch(err => {
										console.log(err.response);
										setLoading(false);
										mySwal.fire("Error", err.response.data.error, "error");
									});
								// console.log(sendThis);
								// register(sendThis);
							} else if (sendOtp && !otp) {
								mySwal.fire(t("Error"), t("Please enter the confirmation code that was sent to your phone"), "error");
							} else {
								// console.log(sendThis);
								register(sendThis);
							}
						//})
				}
			}
		}
	};

	return (
		<div className="px-5 mt-5">
			<div className="text-center mb-3">
				<h3 className="font-weight-bold" style={{ color: "white" }}>
					{t("REGISTER")}
				</h3>
			</div>

			<form onSubmit={handleSubmit}>
				<div className="input-field col">
					<input type="text" id="number" name="number" className="form-control form-input" placeholder={t("Example: 012xxxxxxx")} onChange={e => (!sendOtp ? setNumber(e.target.value) : null)} value={number} autoFocus required />
					<span className="form-prepend">
						<i className="fas fa-phone"></i>
					</span>
					<label className="form-label" htmlFor="number">
						{t("Phone Number")}<small> : ({t("Example: 012xxxxxxx")})</small>
					</label>
				</div>

				<div className="input-field col">
					<input type="text" id="name" name="name" className="form-control form-input" onChange={e => (!sendOtp ? setName(e.target.value) : null)} value={name} required />
					<span className="form-prepend">
						<i className="fas fa-signature"></i>
					</span>
					<label className="form-label" htmlFor="name">
						{t("Name")}
					</label>
				</div>

				<div className="input-field col">
					<input type="email" id="email" name="email" className="form-control form-input" onChange={e => (!sendOtp ? setEmail(e.target.value) : null)} value={email} />
					<span className="form-prepend">
						<i className="fas fa-envelope"></i>
					</span>
					<label className="form-label" htmlFor="email">
						{t("Email")} <small>({t("optional")})</small>
					</label>
				</div>

				<div className="row mt-3 px-3">
					<div className="col">
						<label className="form-label" htmlFor="year">
							{t("Birth Year")}
						</label>
						<select className="form-select" onChange={e => (!sendOtp ? setYear(e.target.value) : null)} value={year} required>
							<option value="" disabled>
								{t("Year")}
							</option>
							{renderYears()}
						</select>
					</div>

					<div className="col">
						<label className="form-label" htmlFor="month">
							{t("Birth Month")}
						</label>
						<select
							className="form-select"
							onChange={e => {
								if (!sendOtp) setMonth(e.target.value);
								if (!sendOtp) setDay("");
							}}
							value={month}
							required
						>
							<option value="" disabled>
								{t("Month")}
							</option>
							<option value="January">{t("Jan")}</option>
							<option value="Febuary">{t("Feb")}</option>
							<option value="March">{t("Mar")}</option>
							<option value="April">{t("April")}</option>
							<option value="May">{t("May")}</option>
							<option value="June">{t("June")}</option>
							<option value="July">{t("July")}</option>
							<option value="August">{t("Aug")}</option>
							<option value="September">{t("Sept")}</option>
							<option value="October">{t("Oct")}</option>
							<option value="November">{t("Nov")}</option>
							<option value="December">{t("Dec")}</option>
						</select>
					</div>

					<div className="col">
						<label className="form-label" htmlFor="day">
							{t("Birth Day")}
						</label>
						<select className="form-select" onChange={e => (!sendOtp ? setDay(e.target.value) : null)} value={day} style={{ width: "100%" }} required>
							<option value="" disabled>
								{t("Day")}
							</option>
							{renderDates()}
						</select>
					</div>
				</div>

				<div className="input-field col">
					<input type={show ? "text" : "password"} id="password" name="password" className="form-control form-input" onChange={e => (!sendOtp ? setPassword(e.target.value) : null)} value={password} minLength="6" required />
					<span onClick={() => setShow(!show)} className="form-prepend">
						<i className={show ? "fas fa-eye" : "fas fa-eye-slash"}></i>
					</span>
					<label className="form-label" htmlFor="password">
						{t("Password")}
					</label>
				</div>

				<div className="input-field col">
					<input type={showConfirmation ? "text" : "password"} id="confirmationPassword" name="confirmationPassword" className="form-control form-input" onChange={e => (!sendOtp ? setConfirmationPassword(e.target.value) : null)} value={confirmationPassword} minLength="6" required />
					<span onClick={() => setShowConfirmation(!showConfirmation)} className="form-prepend">
						<i className={showConfirmation ? "fas fa-eye" : "fas fa-eye-slash"}></i>
					</span>
					<label className="form-label" htmlFor="confirmationPassword">
						{t("Confirmation Password")}
					</label>
				</div>

				{referral ? (<div className="input-field col">
					<input type="text" id="referId" name="referId" className="form-control form-input" onChange={e => setReferId(e.target.value)} value={referId} />
					<span className="form-prepend">
						<i className="fas fa-id-card"></i>
					</span>
					<label className={referId ? "form-label active" : "form-label"} htmlFor="referId">{t("Referral Code")} <small>({t("optional")})</small></label>
				</div>) : null}

				{sendOtp ? (
					<div>
						<div className="input-field col">
							<input type="text" minLength="6" maxLength="6" id="otp" name="otp" className="form-control form-input" onChange={e => setOtp(e.target.value)} value={otp} required />
							<span className="form-prepend">
								<i className="fas fa-key"></i>
							</span>
							<label className="form-label" htmlFor="otp">
								{t("OTP Confirmation")}
							</label>
						</div>

						<OtpButton number={number} operatorId={queryString.parse(query).operatorCode || operatorId} />
					</div>
				) : null}

				<div className="form-group form-check text-center">
					<input type="checkbox" id="agree" name="agree" className="form-check-input" value={agree} onChange={e => setAgree(e.target.value)} required />
					<label htmlFor="agree" className="form-check-label">
						{t("I agree to the")} <Link to="/privacyPolicy" id="privacy-policy">{t("Privacy Policy")}</Link> {t("and")} <Link to="/termsConditions" id="terms-conditions">{t("Terms")} &amp; {t("Conditions")}</Link>
					</label>
				</div>

				<div className="mb-1 px-4 mt-2">
					{isLoading || loading ? (
						<div className="text-center">
							<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					) : (
						<input id="send-code" type="submit" value={sendOtp ? t("REGISTER") : t("SEND CODE")} className="big-button form-control btn" />
					)}
				</div>

				{error ? (
					<div className="text-center m-0 p-0">
						<p style={{ color: "red" }} className="m-0 p-0">
							<small>
								<strong>{error}</strong>
							</small>
						</p>
					</div>
				) : null}

				<div className="text-center mt-3">
					<p id="already-have-account" onClick={() => change("login")} style={{ textDecoration: "underline", fontSize: "12px", fontWeight: "bold", color: "white" }}>
						{t("Already have an account? Login")}
					</p>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		isLoading: state.user.isLoading,
		error: state.user.error,
		operatorId: state.user.operatorId,
		machine: state.payment.machine
		// authNumber : state.user.authNumber
	};
};

const mapDispatchToProps = dispatch => {
	return {
		register: data => dispatch(registerUser(data)),
		change: page => dispatch(changePage(page))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
